import { GetServerSideProps } from 'next';

import { prisma } from '../../lib/prisma';

import { Tumbleweed } from '../../components/Animations/Tumbleweed';
import { trackUserEvent } from '../../utils/trackUserEvent';
import { ShadCnButton } from '@/shadcn-ui/components/ui/button';
import { useRouter } from 'next/router';
import { z } from 'zod';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import {
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@/shadcn-ui/components/ui/form';
import { Form } from '@/shadcn-ui/components/ui/form';
import { Input } from '@/shadcn-ui/components/ui/input';
import { useEffect, useState } from 'react';
import { getSession } from 'next-auth/react';
import { BUSINESS_URL_PARAMETER } from '@/hooks/useNavigationWithDefaultParams';
import Image from 'next/image';
import { internalApi } from '@/lib/axios';
import { RequestAccessToBusinessBody } from '../api/business/request-access';

type Props = {
  dashboard: {
    id: string;
    company: {
      id: string;
      name: string;
    } | null;
    business: {
      id: string;
      name: string;
    };
  } | null;

  currentSessionEmail: string | null;
};

export const getServerSideProps: GetServerSideProps<Props> = async (ctx) => {
  try {
    const { query } = ctx;

    await trackUserEvent({ ctx });

    const id = query.dashboardId as string | undefined;

    if (!id) {
      return {
        props: {
          dashboard: null,
          currentSessionEmail: null,
        },
      };
    }

    const session = await getSession(ctx);

    const dashboard = await prisma.businessDashboard.findUnique({
      where: {
        id,
      },
      select: {
        id: true,
        business: {
          select: {
            id: true,
            name: true,
          },
        },
        company: {
          select: {
            id: true,
            name: true,
          },
        },
      },
    });

    if (!session?.user?.email) {
      return {
        props: {
          dashboard,
          currentSessionEmail: null,
        },
      };
    }

    const user = await prisma.user.findUnique({
      where: {
        email: session?.user?.email,
      },
      select: {
        users_on_businesses: true,
      },
    });

    const userOnBusiness = user?.users_on_businesses.find(
      (ubo) => ubo.business_id === dashboard?.business.id,
    );

    if (userOnBusiness) {
      return {
        redirect: {
          destination: `/app/dashboard?${BUSINESS_URL_PARAMETER}=${userOnBusiness.business_id}`,
          permanent: false,
        },
        props: {
          dashboard: null,
          currentSessionEmail: session.user.email,
        },
      };
    }

    return {
      props: {
        dashboard,
        currentSessionEmail: session.user.email,
      },
    };
  } catch (err) {
    console.log(JSON.stringify(err, null, 4));
    return {
      props: {
        dashboard: null,
        currentSessionEmail: null,
      },
    };
  }
};

export default function UnauthenticatedDashboard(props: Props) {
  const dashboardLocalStorageKey = `dashboard-access-${props.dashboard?.id}`;

  const [formState, setFormState] = useState<'idle' | 'submitting' | 'success'>(
    'idle',
  );
  const router = useRouter();

  const formSchema = z.object({
    email: z.string().email('Email inválido'),
  });

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      email: props.currentSessionEmail || '',
    },
  });

  useEffect(() => {
    const hasRequestedAccess = localStorage.getItem(dashboardLocalStorageKey);
    setFormState(hasRequestedAccess ? 'success' : 'idle');
  }, [props.dashboard?.id, dashboardLocalStorageKey]);

  const requestAccess = async (data: z.infer<typeof formSchema>) => {
    if (!props.dashboard?.business.id || !props.dashboard?.company?.id) return;
    try {
      setFormState('submitting');

      const requestAccessBody: RequestAccessToBusinessBody = {
        email: data.email,
        businessId: props.dashboard?.business.id,
        companyId: props.dashboard?.company?.id,
      };

      await internalApi.post('/api/business/request-access', requestAccessBody);

      localStorage.setItem(dashboardLocalStorageKey, 'true');
      setFormState('success');
    } catch (error) {
      setFormState('idle');
    }
  };

  if (!props.dashboard) {
    return (
      <>
        <title>Dashboard</title>
        <div className="flex flex-col w-full h-screen justify-center items-center">
          <h2 className="mt-6 mb-8 text-[28px]">
            O Dashboard que você procura não está aqui
          </h2>

          <Tumbleweed />

          <ShadCnButton variant="adstart" onClick={() => router.push('/')}>
            Voltar
          </ShadCnButton>
        </div>
      </>
    );
  }

  return (
    <>
      <title>Dashboard</title>

      <div className="flex px-4 items-center justify-center w-full min-h-screen">
        <div className="flex flex-col space-y-8 justify-center">
          <Image
            src="/logo3.svg"
            alt="Dashboard lock"
            width={200}
            height={40}
          />

          <div className="flex flex-col space-y-1 items-center">
            <p className="text-xl font-bold">
              Este dashboard é privado e pertence à{' '}
              {props.dashboard.business.name}
            </p>

            <p className="text-md">
              Solicite seu acesso para a {props.dashboard.company?.name}
            </p>
          </div>

          <Form {...form}>
            <form
              onSubmit={form.handleSubmit(requestAccess)}
              className="space-y-4"
            >
              <FormField
                control={form.control}
                name="email"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>
                      Email{' '}
                      {props.currentSessionEmail ? ' (seu login atual)' : ''}
                    </FormLabel>
                    <FormControl>
                      <Input
                        placeholder="seu@email.com"
                        {...field}
                        disabled={!!props.currentSessionEmail}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />

              <ShadCnButton
                type="submit"
                disabled={formState !== 'idle'}
                className="w-full"
                variant={'adstart'}
              >
                {formState === 'submitting'
                  ? 'Enviando...'
                  : formState === 'success'
                    ? 'Solicitação enviada'
                    : 'Solicitar acesso'}
              </ShadCnButton>

              {formState === 'success' && (
                <p className="text-sm text-gray-500 text-center mt-2">
                  Já notificamos a {props.dashboard.company?.name} sobre sua
                  solicitação
                </p>
              )}
            </form>
          </Form>
        </div>
      </div>
    </>
  );
}
