import axios from 'axios';

export const internalApi = axios.create({
  baseURL: '/api',
});

export const externalApi = axios.create({
  baseURL: process.env.NEXT_PUBLIC_EXTERNAL_API_URL || 'http://localhost:3333',
});

export const isAxiosError = axios.isAxiosError;
