;

import { FiAlertCircle, FiCheck } from 'react-icons/fi';
import { IoIosAlert, IoMdAlert } from 'react-icons/io';
import {
  Toast,
  ToastClose,
  ToastDescription,
  ToastProvider,
  ToastTitle,
  ToastViewport,
} from 'src/shadcn-ui/components/ui/toast';
import { useToast } from 'src/shadcn-ui/components/ui/use-toast';

export function Toaster() {
  const { toasts } = useToast();

  const getIcon = (variant: string | undefined) => {
    switch (variant) {
      case 'destructive':
        return (
          <p className="text-[#e53e3e] w-5 h-5 flex justify-center items-center font-bold text-base bg-white rounded-full">
            !
          </p>
        );
      case 'success':
        return (
          <FiCheck
            className="text-[#38a169] border bg-white rounded-full"
            size={20}
            strokeWidth={2}
          />
        );
      default:
        return null;
    }
  };

  return (
    <ToastProvider>
      {toasts.map(function ({
        id,
        title,
        description,
        action,
        variant,
        ...props
      }) {
        const icon = getIcon(variant as string | undefined);
        return (
          <Toast key={id} variant={variant} {...props}>
            <div className="flex gap-3">
              {icon && <div>{icon}</div>}
              <div>
                {title && (
                  <ToastTitle className="text-base font-bold leading-tight">
                    {title}
                  </ToastTitle>
                )}
                {description && (
                  <ToastDescription className="text-base leading-tight">
                    {description}
                  </ToastDescription>
                )}
              </div>
            </div>
            {action}
            <ToastClose />
          </Toast>
        );
      })}
      <ToastViewport />
    </ToastProvider>
  );
}
